<template>
  <List
    :columnAttr="columnAttr"
    :dataList="dataList"
    class="template-list"
    :class="{simple:simple}"
    :isIndent="isIndent"
    :isLoading="isLoading"
    :header="header"
    :hasCheckbox="hasCheckbox"
    :customHeader="customHeader"
    :page="page"
    @rowClick="handleRowClick"
    :simple="simple"
  >
    <template #table_header>
      <slot name="table_header"></slot>
    </template>
    <template #env_time="{ row }">
      <div :class="['env_time', { hasPadding: !hasCheckbox }]">
        <div class="win-icon">
          <!-- <img :src="getOSIcon(row.os, row.os_env)" alt="" /> -->
          <svg-icon :name="getOSIcon(row.os, row.os_env, row.strategy, row)"></svg-icon>
        </div>
        <div class="env_time-text">
          <div class="env">{{osName(row)}}</div>
          <div v-html="formatUTC(row.added_on)" class="time"></div>
        </div>
      </div>
    </template>
    <template #type_score="{ row }">
      <div class="type_score">
        <score v-if="row.status === 'reported'" :score="row.score"></score>
        <span v-if="page === 'history' && row.status !== 'reported'" class="status">
          {{ getStatus(row.status) }}
        </span>
        <div class="file-icon">
          <img :src="filetypeIcon(row)" alt="" />
          <div class="file_type">{{ row.file_type || (row.category === 'url' ? 'URL' : '') }}</div>
        </div>
      </div>
    </template>
    <template #name_type_tags="{ row }">
      <div class="name_type_tags">
        <div class="filename">
          {{ isGlobal ? (row.category === 'md5' ? row.md5 : row.url) : row.target }}
        </div>
        <!-- <div class="file_type">{{ row.file_type }}</div> -->
        <div class="tags">
          <TagList :data="row.tag" :showMore="row.filter" />
        </div>
      </div>
    </template>
    <template #hash="{ row }">
      <div class="hash">
        <div v-for="item in ['md5', 'sha1', 'sha256']" :key="item" class="hash-item">
          <span class="label">{{ item.toUpperCase() }}：</span>
          <span class="value" v-if="row[item]">
            <copy-icon
              :name="`${item}${row.id}`"
              :copyText="row[item]"
              style="vertical-align: middle;display:inline-block;width:20px;"
              :grey="true"
            />
            {{ row[item] }}
          </span>
          <span class="value" v-else style="paddingLeft:6px;">-</span>
        </div>
      </div>
    </template>
    <template #public="{ row }">
      <div class="public">
        <div>
          <div class="public-desc">
            <span
              v-for="item in publicList"
              :key="item.value"
              :class="['public-btn', { active: item.value === row.is_public }, {disabled : gid <= 1}]"
              @click="$emit('public', row.id, item.value !== row.is_public ? item.value : '')"
            >
              {{ item.label }}
            </span>
            <!-- {{row.is_public ? '已公开' : '私有'}} -->
          </div>
          <div
            v-if="
              teamList.length > 0 &&
              row.team_id &&
              row.team_id.length > 0 &&
              handleTeamInfo(row.team_id).count > 0
            "
            class="team-show"
          >
            <svg-icon name="tuandui"></svg-icon>
            <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" placement="bottom-start">
              <span class="team-name" v-if="handleTeamInfo(row.team_id).count == 1">{{ handleTeamInfo(row.team_id).text }}</span>
              <span v-else>{{handleTeamInfo(row.team_id).count}}</span>
              <template #content>
                <div>
                  <div v-html="handleTeamInfo(row.team_id).text"></div>
                </div>
              </template>
            </el-tooltip>
          </div>
        </div>
      </div>
    </template>
    <template #confirm="{ row }">
      <div class="public" style="padding-right: 0;">
        <div>
          <div class="public-desc">
            <!-- <span
              v-for="item in confirmList"
              :key="item.value"
              :class="['public-btn', { active: item.value === row.is_public }, {disabled : gid <= 1}]"
              @click="$emit('confirm', row.id, item.value)"
            >
              {{ item.label }}
            </span> -->
            <el-button-group>
              <el-button v-for="item in confirmList" :key="item.value" :type="row.confirm_status == item.value ? 'primary' : 'plain'" :class="['switch-tab-item confirm-btns']" :disabled="disableConfirm && (+row.confirm_status !== +item.value)" @click="$emit('confirm', row, item.value)">
                <template #default>
                  <el-tooltip v-if="disableConfirm && (+row.confirm_status !== +item.value)" :effect="$isDark() ? 'dark' : 'light'" content="没有标记告警任务的权限，请联系规则创建者">
                    <span class="confirm-btns">{{row.confirm_status == item.value ? item.labeled : item.label}}</span>
                  </el-tooltip>
                  <span v-else class="confirm-btns">{{row.confirm_status == item.value ? item.labeled : item.label}}</span>
                </template>
              </el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </template>
    <template #owner="{ row }">
      <div class="owner">
        <div class="owner-item">
          <!-- <svg-icon name="zhanghu"></svg-icon> -->
          <span>用户名：</span>
          <span>
            <copy-icon
              :name="`u-${row.owner_id}`"
              :copyText="row.owner_name !== '未知' && row.owner_name ? row.owner_name : '未登录用户'"
              style="vertical-align: middle;display:inline-block;width:13px;"
              :grey="true"
            />
            {{ row.owner_name !== '未知' && row.owner_name ? row.owner_name : '未登录用户' }}
          </span>
        </div>
        <div class="owner-item">
          <span>用户ID：</span>
          <span>
            <copy-icon
              :name="`id-${row.owner_id}`"
              :copyText="row.owner_id + ''"
              style="vertical-align: middle;display:inline-block;width:13px;"
              :grey="true"
            />
            {{ row.owner_id }}
          </span>
        </div>
      </div>
    </template>
    <template #action="{ row }">
      <div class="public">
        <el-dropdown>
          <el-button type="primary" @click="stopDefaultEvent">
            下载<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleDownload('sample', row)" :disabled="!row.has_sample">下载样本</el-dropdown-item>
              <el-dropdown-item @click="handleDownload('pcap', row)">下载PCAP</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </template>
  </List>
</template>
<script>
import List from '@/components/latestReport/list'
import TagList from '@/components/common/tagList'
import { ArrowDown } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { t } from 'app/i18n'
import score from '../echarts/score'
import { download } from 'utils/tools'
import { downloadSample, getDownloadPcapUrl } from 'services/report'
export default {
  name: 'TempalteList',
  components: {
    List,
    TagList,
    score,
    ArrowDown
  },
  props: {
    columnAttr: {
      type: Array
    },
    dataList: {
      type: Array
    },
    isIndent: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    },
    page: {
      type: String
    },
    header: {
      type: Boolean
    },
    hasCheckbox: {
      type: Boolean
    },
    isGlobal: {
      type: Boolean,
      default: false
    },
    customHeader: {
      type: Boolean
    },
    disableConfirm: {
      type: Boolean
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      publicList: [
        {
          label: t('detection.permissions.active'),
          value: true
        },
        {
          label: t('detection.permissions.inactive'),
          value: false
        }
      ],
      confirmList: [
        {
          labeled: '已确认',
          label: '确认',
          value: '1'
        },
        {
          labeled: '已误报',
          label: '误报',
          value: '2'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      teamList: 'team/teamList',
      gid: 'gid'
    }),
    osName() {
      return (item) => {
        if ((item.strategy === 0 && !item.real_os_env) || item.strategy === 1) {
          return t('taskhub.static')
        }
        if (isNaN(Number(item.os_env[item.os_env.length - 1]))) return item.os_env?.join(' ')
        return `${item.os_env.join(' ')}${t('config.basic.os.suffix')}`
      }
    }
  },
  methods: {
    formatUTC(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
    },
    handleTeamInfo(teamIds) {
      const teamIdsOfUserInvited = this.teamList.map((item) => item.team_id)
      const teamNameList = teamIdsOfUserInvited
        .filter((item) => teamIds.includes(item))
        .map((item) => this.teamList.find((item2) => item2.team_id === parseInt(item)).team_name)
      return { text: teamNameList.join('<br />'), count: teamNameList.length }
    },
    handleDownload(key, row) {
      if (key === 'pcap') {
        this.downloadPcap(row)
      } else {
        if (!row.has_sample) return
        this.downloadSampleFn(row.download_sample)
      }
    },
    downloadPcap(row) {
      // 获取下载地址，执行下载
      getDownloadPcapUrl(row.id).then(res => {
        console.log(res)
        if (res.has_pcap) {
          this.downloadSampleFn(res.pcap_url)
        } else {
          this.$store.commit('app/showMessage', {
            type: 'error',
            message: '文件不存在',
            duration: 1000
          })
        }
      }).catch(() => {
        this.$store.commit('app/showMessage', {
          type: 'error',
          message: '文件不存在',
          duration: 1000
        })
      })
    },
    downloadSampleFn(url) {
      downloadSample(url.replace('/api/v1', ''))
        .then((res) => {
          const filename = res.headers['content-disposition'].match(/filename=(.+)/)[1]
          download({
            content: res.data,
            type: 'application/octet-stream',
            filename
          })
        })
        .catch(() => {
          this.$store.commit('app/showMessage', {
            type: 'error',
            message: '文件不存在',
            duration: 1000
          })
        })
    },
    stopDefaultEvent(event) {
      try {
        event.stopDefaultEvent()
      } catch {
        window.event.cancelBubble = true
      }
    },
    handleRowClick(event, row) {
      // 阻止跳转
      const preventList = [
        // 标签
        'tabList-item',
        // 标签箭头
        'tabList-img',
        'tagList-lock',
        // 拷贝
        'copy',
        // 公开私有按钮
        'public-btn',
        // 单选框
        'el-checkbox__inner',
        'el-checkbox__original',
        'list-row__checkbox',
        'svg-icon',
        'confirm-btns'
      ]
      console.log(event.target)
      if (Array.from(event.target.classList).find((item) => preventList.includes(item))) return
      if (row.status.includes('failed')) {
        this.$emit('deleteFailed', row.id)
        return
      }
      if (row.id) {
        const router = { name: 'report', params: { taskId: row.id } }
        const { href } = this.$router.resolve(router)
        window.open(href, '_blank')
      }
      if (row.confirm_status === 0 || row.confirm_status) {
        const router = { name: 'report', params: { taskId: row.task_id } }
        const { href } = this.$router.resolve(router)
        window.open(href, '_blank')
      }
    },
    scoreType(score) {
      if (score < 5) {
        return { bg: 'green', title: t('app.home.clean') }
      } else if (score < 7.5) {
        return { bg: 'orange', title: t('app.home.suspicious') }
      } else if (score >= 7.5) {
        return { bg: 'red', title: t('app.home.malicious') }
      } else {
        return { bg: 'green', title: t('app.home.clean') }
      }
    },
    tagType(level) {
      if (level === 1) {
        return 'blue'
      } else if (level === 2) {
        return 'yellow'
      } else if (level >= 3) {
        return 'red'
      } else {
        return ''
      }
    },
    filetypeIcon(row) {
      if (row.os === 'android') {
        return require('@/assets/img/svg/filetype/apk.svg')
      }
      const type = row.file_type
      let file = ''
      if (row.category === 'file' || type) {
        const list = [
          'html',
          'hwp',
          'js',
          'mhtml',
          'powershell',
          'python',
          'rar',
          'rtf',
          'swf',
          'VBS-icon',
          'email'
        ]
        if (list.includes(type)) {
          file = type
        } else if (type === 'doc' || type === 'docx') {
          file = 'doc'
        } else if (type === 'xls' || type === 'xlsx') {
          file = 'excel'
        } else if (type === 'dll32' || type === 'dll64') {
          file = 'dll'
        } else if (type === 'exe32' || type === 'exe64' || type === 'nsis_exe') {
          file = 'exe'
        } else if (type === 'ppt' || type === 'pptx') {
          file = 'ppt'
        } else if (type === 'bzip2' || type === 'gzip' || type === 'zip') {
          file = 'zip'
        } else {
          file = 'unknow'
        }
      } else if (row.category === 'url') {
        file = 'IE'
      } else if (row.category === 'md5') {
        file = 'md5'
      }
      return require(`@/assets/img/svg/filetype/${file}.svg`)
    },
    getOSIcon(os, env, strategy, row) {
      let os2 = os !== 'linux' ? os : env[0].toLowerCase()
      if (strategy === 1 || (strategy === 0 && !row?.real_os_env)) {
        os2 = 'static'
      }
      const keyMap = {
        ubuntu: 'ubuntu',
        linux: 'linux',
        android: 'android',
        windows: 'windows',
        static: 'static-big'
      }
      return keyMap[os2] ? keyMap[os2] : 'windows'
    },
    getStatus(status) {
      switch (status) {
        case 'waiting':
        case 'pending':
          // return '排队中'
          return t('other.components.report.pending')
        case 'scanning':
        case 'scanning_completed':
          // return '静态检测中'
          return t('other.components.report.scanning')
        case 'dynamic_pending':
        case 'static_dispatch_waiting':
        case 'dynamic_dispatch_waiting':
          // return '调度中'
          return t('other.components.report.dynamic_pending')
        case 'running':
        case 'processing':
          // return '动态检测中'
          return t('other.components.report.running')
        case 'completed':
          // return '检测完成'
          return t('other.components.report.completed')
        default:
          // return '检测失败'
          return t('other.components.report.runningErr')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-list {
  margin-top: 6px;
  // height: calc(100vh - 334px);
  // height: calc(100vh - 705px);
  // overflow: auto;
  & :deep(.list-row-body) {
    .list-row {
      cursor: pointer;
      // bg_color(color-table-body-row-bg);
      border-radius: 4px;

      .tabList-box {
        .tabList-item--low {
          // color: #748EFB;
          // border-color: #748EFB;
        }
      }

      .list-row__item {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .env_time {
          display: flex;
          justify-content: center;

          &.hasPadding {
            padding-left: 28px;
            box-sizing: border-box;
          }

          .win-icon {
            display: flex;
            align-items: center;
            margin-right: 10px;
            width: 40px;
            font-size: 36px;
            color: var(--color-text-3);
            flex-shrink: 0;
          }

          .env_time-text {
            font-size: 14px;
            width: calc(100% - 75px);
            text-align: left;

            .env {
              width: 100%;
              line-height: 21px;
              margin-bottom: 2px;
              font-size: 14px;
              color: var(--color-text-2);
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .time {
              color: var(--color-text-2);
              opacity: 0.7;
              margin-top: 12px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .type_score {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          .score {
            // min-width: 80px;
            width: 80px;
            height: 70px;
            margin: 0 auto;
          }
          .status {
            color: var(--color-text-2);
            margin: 0 auto;
          }

          .file-icon {
            margin-left: 20px;
            width: 80px;
            img {
              display: inline-block;
              width: 26px;
              height: 26px;
              vertical-align: middle;
              padding-bottom: 8px;
              filter: var(--svg-filter1);
            }
            .file_type {
              color: var(--color-text-2);
              font-size: 14px;
              line-height: 14px;
              font-weight: 500;
            }
          }

          .score-wrap {
            display: inline-block;
            color: var(--color-text-1);
            border-radius: 4px;
            font-size: 14px;
            width: 60px;
            height: 22px;
            line-height: 22px;
            font-family: Verdana;
            font-weight: 600;
            background: #ccc;

            &.green {
              background: #3EAE89;
            }

            &.orange {
              background: #E69F34;
            }

            &.red {
              background: #CF605B;
            }
          }

          .status {
            color: var(--color-text-2);
          }
        }

        .name_type_tags {
          font-size: 14px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left;
          padding: 20px 20px 13px;
          box-sizing: border-box;
          min-height: 85px;

          .filename {
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            // margin-bottom: 2px;
            color: var(--color-text-1);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .file_type {
            font-size: 14px;
            height: 14px;
            margin-bottom: 5px;
            // ft_color(color-table-body-filetype);
            font-weight: 500;
          }

          .tags {
            width: 100%;
            margin-top: 12px;
          }
        }

        .hash {
          text-align: left;
          width: 100%;
          // ft_color(color-table-body-minor);
          padding: 0 30px;
          box-sizing: border-box;

          .hash-item {
            line-height: 20px;
            font-size: 14px;
            width: 100%;
            height: 20px;
            padding: 2px 0;
            span {
              vertical-align: middle;
            }

            .label {
              display: inline-block;
              width: 70px;
              color: var(--color-text-2);
            }

            .value {
              display: inline-block;
              width: calc(100% - 90px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: var(--color-text-2);
            }
          }
        }

        .public {
          display: flex;
          // justify-content: center;
          flex-direction: row-reverse;
          padding-right: 16px;

          .public-desc {
            // font-size: 14px;
            color: var(--color-text-2);
            // text-align: left;
            display: flex;
            justify-content: center;
            overflow: hidden;
            .disabled {
              cursor: auto !important;
            }

            .public-btn {
              display: inline-block;
              box-sizing: border-box;
              border: 1px solid var(--color-border-2);
              border-radius: 0px 2px 2px 0px;
              width: 58px;
              height: 26px;
              line-height: 24px;
              font-size: 12px;
              cursor: pointer;
              // ft_color(color-button-tab);

              &.active {
                // bg_color(color-main-highlight);
                background-color: #009e71;
                color: #fff;
              }

              &:first-child {
                border-radius: 2px 0px 0px 2px;
                border-right: none;
              }

              &:last-child {
                border-radius: 0 2px 2px 0;
                border-left: none;
              }

              &:hover:not(.active) {
                // ft_color(color-main-highlight);
              }
            }
            .switch-tab-item {
              width: 60px;
              &.el-button--plain {
                border-color: #009e71;
                color: #009e71;
              }
              &.active {
                background-color: #009e71;
                color: #fff;
              }
              &:focus-visible {
                outline: none;
              }
            }
          }

          .team-show {
            font-size: 14px;
            display: flex;
            align-items: center;
            color: var(--color-text-2);
            margin-top: 10px;
            span {
              margin-right: 4px;
            }
            .svg-icon {
              margin-right: 4px;
              color: var(--color-text-3);
            }
            .team-name {
              display: inline-block;
              max-width: 96px;
              height: 20px;
              line-height: 20px;
              overflow: hidden;
              white-space:nowrap;
              text-overflow:ellipsis;
            }
          }
        }
      }

      .owner {
        text-align: left;
        width: 100%;
        // ft_color(color-table-body-minor);
        padding: 0 10px;
        box-sizing: border-box;

        .owner-item {
          line-height: 20px;
          font-size: 12px;
          height: 20px;
          padding: 2px 0;
          width: calc(100% - 10px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--color-text-2);
        }
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      &:hover {
        // bg_color(color-table-body-hover-bg);
        z-index: 1;

        .tabList-box {
          .tabList-item--high {
            // ft_color(color-taglist-high-hover);
            // bd_color(color-taglist-high-hover);
          }

          .tabList-item--middle {
            // ft_color(color-taglist-middle-hover);
            // bd_color(color-taglist-middle-hover);
          }

          .tabList-item--low {
            // ft_color(color-taglist-low-hover);
            // bd_color(color-taglist-low-hover);
          }
        }
      }
    }
  }
}
</style>
