<template>
  <span :class="['caret-wrapper', order]" @click="handldChangeSort">
    <i class="sort-caret ascending"></i>
    <i class="sort-caret descending"></i>
  </span>
</template>

<script setup>
import { defineProps, ref, onMounted, onUnmounted } from 'vue'
import emitter from '@/app/emitter.js'

const order = ref('')

const props = defineProps({
  module: {
    type: String,
    default () {
      return ''
    }
  },
  attr: {
    type: String,
    default () {
      return ''
    }
  }
})

const handldChangeSort = () => {
  switch (order.value) {
    case '':
      order.value = 'ascending'
      break
    case 'ascending':
      order.value = 'descending'
      break
    case 'descending':
      order.value = ''
      break
    default: break
  }
  emitter.emit(`${props.module}/sort`, {
    order: order.value,
    attr: props.attr
  })
}

onMounted(() => {
  emitter.on(`${props.module}/sort`, ({ attr }) => {
    (attr !== props.attr) && (order.value = '')
  })
})
onUnmounted(() => {
  emitter.off(`${props.module}/sort`)
})
</script>

<style lang="scss" scoped>
  .caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 10px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
    .sort-caret {
      width: 0;
      height: 0;
      border: 5px solid transparent;
      position: absolute;
    }
    .sort-caret.ascending {
      // border-bottom-color: $color-sort-icon;
      top: 5px;
    }
    .sort-caret.descending {
      // border-top-color: $color-sort-icon;
      bottom: 7px;
    }
  }
  .caret-wrapper.ascending {
    .sort-caret.ascending {
      // border-bottom-color: $color-sort-icon-active;
    }
  }
  .caret-wrapper.descending {
    .sort-caret.descending {
      // border-top-color: $color-sort-icon-active;
    }
  }
</style>
