<template>
  <div :class="['newest-list-wrap', {indent: isIndent}]">
    <el-row class="list-header" v-if="header&&dataList.length">
      <el-col :span="1" v-if="hasCheckbox">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        ></el-checkbox>
      </el-col>
      <template v-if="!customHeader">
        <el-col v-for="column in columnAttr"
          :key="column.props"
          :span="column.span || span"
        >
          {{column.label}}
          <sort-icon
            v-if="column.sort"
            module="newest"
            :attr="column.props"
          />
        </el-col>
      </template>
      <slot name="table_header" v-else></slot>
    </el-row>
    <el-row
      v-if="isLoading"
      v-loading="isLoading"
      :element-loading-text="$t('loading')"
      element-loading-background="transparent"
      class="table-loading"
    ></el-row>
    <el-checkbox-group
      v-else
      v-model="checkedRowList"
      @change="handleCheckedRow"
      class="list-body"
    >
      <div :class="['list-row-body', {indent: isIndent, simple:simple}]">
        <el-row v-if="dataList.length === 0" class="list-row-noData">
          <!-- 没有可展示的任务报告 -->
          <no-data style="marginTop:150px;" :data="$t('taskhub.nodata')"></no-data>
        </el-row>
        <el-row
          v-else
          v-for="row in dataList"
          class="list-row"
          :key="row.id"
          @click="$emit('rowClick', $event, row)"
        >
          <el-col
            v-if="hasCheckbox"
            :span="1"
            class="list-row__checkbox"
          >
            <el-checkbox
              :label="row.id"
              :key="row.id"
            >{{''}}</el-checkbox>
          </el-col>
          <el-col
            v-for="column in columnAttr"
            :key="column.props"
            :span="column.span || span"
            :class="['list-row__item', column.props]"
          >
            <slot :name="column.props" :row="row"></slot>
          </el-col>
        </el-row>
      </div>
    </el-checkbox-group>
  </div>
</template>

<script>
import SortIcon from '@/components/common/SortIcon'
export default {
  name: 'List',
  components: {
    SortIcon
  },
  props: {
    hasCheckbox: {
      type: Boolean,
      default: false
    },
    columnAttr: {
      type: Array,
      default () {
        return [
          {
            label: '列1',
            props: 'column1',
            span: 2
          },
          {
            label: '列2',
            props: 'column2',
            sort: true,
            span: 4
          },
          {
            label: '列3',
            props: 'column3',
            span: 6
          },
          {
            label: '列4',
            props: 'column4',
            span: 8
          }
        ]
      }
    },
    gutter: {
      // 列间隔
      type: Number,
      default: 4
    },
    dataList: {
      type: Array,
      default () {
        return [
          {
            task_id: 1,
            column1: 'value1',
            column2: 'value2',
            column3: 'value3',
            column4: 'value4'
          },
          {
            task_id: 2,
            column1: 'value1',
            column2: 'value2',
            column3: 'value3',
            column4: 'value4'
          },
          {
            task_id: 3,
            column1: 'value1',
            column2: 'value2',
            column3: 'value3',
            column4: 'value4'
          }
        ]
      }
    },
    isIndent: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    },
    header: {
      type: Boolean,
      default () {
        return true
      }
    },
    customHeader: {
      type: Boolean,
      default () {
        return false
      }
    },
    page: {
      type: String
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'checkedRowList.length' () {
      const that = this
      const checkList = this.dataList.filter(item => {
        return that.checkedRowList.includes(item.id)
      })
      this.$emitter.emit(`${this.page}/checkedRowList`, checkList)
    }
  },
  computed: {
    span () {
      // 有span的数量
      let hasSpanCount = 0
      // 判断是否有选择框
      const hasCheckbox = Number(this.hasCheckbox)
      // 计算总span数
      let spanTotal = this.columnAttr.reduce((total, item) => {
        total += item.span || 0
        item.span && (hasSpanCount += 1)
        return total
      }, 0)
      spanTotal = spanTotal > (24 - hasCheckbox) ? (24 - hasCheckbox) : spanTotal
      const leftSpanCount = this.columnAttr.length - hasSpanCount
      return Math.floor((24 - spanTotal - hasCheckbox) / leftSpanCount)
    },
    tableDataIds () {
      return this.dataList.map(row => row.id)
    },
    checkedRowListBesideTable () {
      return this.checkedRowList.filter(id => !this.tableDataIds.includes(id))
    }
  },
  data () {
    return {
      checkAll: [],
      isIndeterminate: false,
      checkedRowList: []
    }
  },
  methods: {
    handleCheckedRow () {
      if (this.checkedRowList.length === 0) {
        this.isIndeterminate = false
        this.checkAll = false
      } else if (this.isAllInCheckedRowList) {
        this.isIndeterminate = false
        this.checkAll = true
      } else {
        this.isIndeterminate = true
        this.checkAll = false
      }
    },
    handleCheckAllChange (val) {
      if (val) {
        // 全选
        this.checkedRowList = this.checkedRowListBesideTable.concat(this.tableDataIds)
        this.isIndeterminate = false
      } else {
        // 全部取消
        this.checkedRowList = this.checkedRowListBesideTable
        this.isIndeterminate = !!this.checkedRowListBesideTable.length
        this.checkAll = false
      }
    }
  },
  mounted () {
    const that = this
    this.$emitter.on(`${this.page}/resetChecked`, () => {
      that.handleCheckAllChange(false)
    })
  },
  unmounted () {
    this.$emitter.off(`${this.page}/resetChecked`)
  }
}
</script>

<style lang="scss" scoped>
  .newest-list-wrap {
    font-size: 14px;
    .list-header {
      display: flex;
      height: 35px;
      background: var(--detect-config-bg);
      box-shadow: 0 0 10px 0 var(--popover-box-shadow1);
      border-radius: 2px;
      margin: 0px 0 6px;
      padding-left: 4px;
      .el-col {
        text-align: center;
        line-height: 34px;
        // :deep(.el-checkbox__inner),
        // .el-checkbox__inner:hover {
        //   border-color: #868EC2;
        //   background: transparent !important;
        // }
      }
    }
    .list-header.indent {
      padding: 9px 20px;
    }
    .table-loading {
      text-align: center;
      height: calc(100vh - 500px);
    }
    .list-body {
      font-size: 14px;
      // height: calc(100vh - 374px);
      // overflow-y: scroll;
      .list-row-body {
        &.simple {
          .list-row {
            box-shadow: none;
            border-top: 1px solid var(--color-border-1) !important;
            margin-bottom: 0 !important;
            &:first-child {
              border-top: none !important;
            }
          }
        }
        .list-row-noData {
          padding: 20px 0;
          text-align: center;
          font-size: 18px;
          color: var(--color-text-1);
        }
        .list-row {
          background: var(--detect-config-bg);
          box-shadow: 0 0 10px 0 var(--popover-box-shadow1);
          border-radius: 2px;
          padding: 2px 4px;
          display: flex;
          box-sizing: border-box;
          margin-bottom: 8px;
          min-height: 88px;
          &:hover {
            background: var(--color-bg-3);
          }
          .el-col:not(.list-row__left) {
            text-align: center;
          }
          .el-col.list-row__checkbox {
            // align-self: flex-start;
            align-self: center;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            // >>> .el-checkbox {
            //   .el-checkbox__input {
            //     .el-checkbox__inner {
            //       background: transparent;
            //       border-color: transparent;
            //     }
            //   }
            // }
            // 选择框背景颜色
            // :deep(.el-checkbox__inner),
            // .el-checkbox__inner:hover {
            //   border-color: #868EC2;
            //   background: transparent !important;
            // }
            // .el-checkbox__input.is-checked .el-checkbox__inner,
            // .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            //   background-color: $color-btn-search-bg;
            // }
            :deep(.el-checkbox__label) {
              padding-left: unset;
            }
          }
        }
        .list-row:not(:first-child) {
          border: 0;
          // border-top: 1px solid;
          // bd_color(color-table-col-seprate);
        }
        .list-row:last-child {
          // border-bottom: 1px solid;
          // bd_color(color-table-col-seprate);
        }
        .list-row:hover+.list-row {
          // border-top-color: transparent;
        }
      }
    }
  }
  .newest-list-wrap.indent {
    padding: 20px;
  }
</style>
