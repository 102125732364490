<template>
  <div ref="chart" class="score"></div>
</template>

<script setup>
import * as echarts from 'echarts'
import { defineProps, ref, onMounted, computed } from 'vue'
import { getColor, watchSwitchTheme } from 'app/style'
import { t } from 'app/i18n'

const chart = ref(null)

const props = defineProps({
  score: {
    default: 0
  }
})

const scoreType = computed(() => props.score > 7.4 ? { label: t('app.home.malicious'), value: '#ff5967' } : (props.score > 4.9 ? { label: t('app.home.suspicious'), value: '#e69f32' } : { label: t('app.home.clean'), value: '#00ab7a' }))
onMounted(() => {
  const instance = echarts.init(chart.value, null, { renderer: 'svg' })

  function init () {
    const option = {
      backgroundColor: 'transparent',
      title: [
        {
          text: props.score !== 0 ? props.score.toFixed(1) : props.score,
          x: 'center',
          top: '35%',
          textStyle: {
            fontFamily: 'Verdana',
            fontSize: '12px',
            color: scoreType.value.value,
            foontWeight: '600'
          }
        }
      ],
      tooltip: {},
      angleAxis: {
        max: 100,
        clockwise: true, // 逆时针
        // 隐藏刻度线
        show: false,
        boundaryGap: ['40%', '40%'],
        startAngle: 90
      },
      radiusAxis: {
        type: 'category',
        show: true,
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      },
      polar: [
        {
          center: ['50%', '50%'], // 中心点位置
          radius: '135%' // 图形大小
        }
      ],
      series: [
        {
          type: 'bar',
          z: 10,
          data: [props.score * 10],
          coordinateSystem: 'polar',
          roundCap: true,
          barWidth: 2, // 大的占比环
          itemStyle: {
            normal: {
              opacity: 1,
              color: scoreType.value.value
            }
          },
          tooltip: {
            show: false
          }
        },
        {
          type: 'pie',
          name: '内层细圆环',
          radius: ['64%', '70%'],
          startAngle: -270,
          hoverAnimation: false,
          clockWise: true,
          itemStyle: {
            normal: {
            // '#282c3d'
              color: getColor('--color-border-2')
            }
          },
          tooltip: {
            show: false
          },
          label: {
            show: false
          },
          data: [100]
        }
      ]
    }

    instance.setOption(option)
  }

  init()

  watchSwitchTheme(() => {
    init()
  })
})
</script>
<style lang="scss" scoped>
.score {
  width: 70px;
  height: 70px;
}
</style>
